import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePickerComponent } from 'ng2-date-picker';
import * as moment from 'moment';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  @ViewChild('dayPicker') datePicker: DatePickerComponent;

  today = moment();

  formButtonText = 'Vers l\'étape 2';
  commentPlaceHolder = "Veuillez indiquer tous les éléments qui pourraient rendre votre déménagement compliqué tels que: pas d'ascenseur, entrée de la porte exigüe, etc."
  tooltipText = 'this is a test';

  devisForm = this.fb.group({
  	demenagementDe: ['', Validators.required],
  	demenagementA: ['', Validators.required],
  	prenom: ['', Validators.required],
  	nom: ['', Validators.required],
  	email: ['', Validators.required],
  	telephone: ['', Validators.required],
  	commentaires: ['']
  });

  devisFormErr = {
    FirstName : '',
    LastName : '',
    Email : '',
    Phone : '',
    MovingFrom : '',
    MovingTo : ''
  };

  datePickerConfig = {
    'locale': 'fr-ch',
    'disableKeypress': true,
    'closeOnSelect': false,
    'allowMultiSelect': true,
    'drops': 'up',
    'opens': 'right',
    'min': this.today,
    'firstDayOfWeek': 'mo'
  };


  activeStep = 1;
  showStep2 = false;
  showStep3 = false;
  isDatePickerOpened = false;
  showDropzone = false;
  isFormSend = false;

  action = 'img-upload.php';

  config = {
    headers: {
      'id': null
    }
  }

  dates = [];
  images = [];

  expressDatesAmount = 0;

  constructor(private fb: FormBuilder,
    public appService: AppService) { }

  isFormValid(): boolean {
  	if (this.activeStep == 1) {
  		return this.devisForm.value.demenagementA != '' && this.devisForm.value.demenagementDe != '' && this.dates != [];
  	} else if (this.activeStep == 2) {
  		return this.devisForm.value.demenagementA != '' && this.devisForm.value.demenagementDe != '' && this.devisForm.value.dates != '' /*&& this.showDropzone*/;
  	} else {
  		return this.devisForm.valid;
  	}
  }

  resolved(captchaResponse: string) {
    this.appService.postRecaptcha(captchaResponse).subscribe(response => {
      if (response == 1) {
        // success
        this.showDropzone = true;
        this.config.headers.id = captchaResponse.slice(0, 10);
      } else {
        // error
      }
    });
  }

  onUploadError(e: any) {
    console.log('error', e);
  }

  onUploadSuccess(e: any) {
    console.log('success', e[1]);
    this.images.push(e[1]);
  }

  onSubmit() {
  	if (this.activeStep == 1) {
  		this.activeStep++;
      this.showStep2 = true;
      this.formButtonText = 'Vers l\'étape 3';
  	} else if (this.activeStep == 2) {
  		this.activeStep++;
  		this.showStep3 = true;
      this.formButtonText = 'Envoyer ma demande';
  	} else {
      this.appService.postForm(JSON.stringify(this.devisForm.value), JSON.stringify(this.images), JSON.stringify(this.dates)).subscribe(response => {
        if(response == "200"){
          this.isFormSend = true;
        }else{
          this.devisFormErr = response;
          console.log(this.devisFormErr);
        }
      });
  	}
  }

  change(moments: any[]) {
    this.dates = [];
    this.expressDatesAmount = 0;

    for (let moment of moments) {
      this.dates.push(moment.format('LL'));

      if (moment.diff(this.today, 'days') < 4) {
        this.expressDatesAmount++;
      }
    }
  }

  toggleDatePicker() {
    if (this.isDatePickerOpened) {
      this.datePicker.api.close();
    } else {
      this.datePicker.api.open();
    }
  }
}
