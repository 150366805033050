import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { AppService } from './app.service';
import { HttpClientModule } from '@angular/common/http';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AppComponent } from './app.component';

const ROMY_DROPZONE_CONFIG: DropzoneConfigInterface = {
   url: 'assets/php/img-upload.php',
   paramName: 'file',
   maxFilesize: 3,
   maxFiles: 5,
   acceptedFiles: 'image/*',
   dictMaxFilesExceeded: 'Vous ne pouvez transférer que {{maxFiles}} fichiers.' 
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgsRevealModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    FormsModule,
    RecaptchaModule,
    HttpClientModule,
    DropzoneModule
  ],
  providers: [
    AppService,
    {
      provide: DROPZONE_CONFIG,
      useValue: ROMY_DROPZONE_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
