import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private http: HttpClient) {}

    postRecaptcha(recaptcha: string): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        let options = {
            headers: headers
        };
        const dataSent = 'grecaptcharesponse=' + recaptcha;
        console.log("POST : ", dataSent);
        return this.http.post<any>('assets/php/recaptcha.php', dataSent, options);
    }

    postForm(form: string, images: string, dates: string): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        let options = {
            headers: headers
        };

        const dataSent = 'form=' + form + '&images=' + images + '&dates=' + dates;
        console.log("POST : ", dataSent);
        return this.http.post<any>('assets/php/validateForm.php', dataSent, options);
    }
}